:root{
    --primary-color: #415B74;
    --secondary-color: #FF8D02;
    --white-color: #FFFFFF;
    --black-color: #000000;
    --border-radius-small: 0.25rem;
}
html, body{
    background-color: #F6F6F6;
}
img{
    max-width: 100%;
}
.btn{
    cursor: pointer;
}
.btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}
.btn-primary:hover, .btn-primary:focus {
    background-color: #517291;
    border-color: #517291;
    box-shadow: 0 0 0 0.25rem rgba(81, 114, 145, 0.2);
}
.btn-outline-primary {
    color: var(--primary-color);
    border-color: var(--primary-color);
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.btn-danger:hover, .btn-danger:focus {
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.25);
}
.back-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    text-decoration: none;
    color: var(--primary-color);
    position: absolute;
    top: 0;
    left: -14px;
}
.back-btn:hover{
    color: var(--secondary-color);
}
form .text-danger{
    font-size: 0.875rem;
}
/* START: Header Navbar CSS */
.header-navbar{
    background-color: #FFFFFF;
    box-shadow: 0 0 7px 3px rgba(0,0,0,0.1);
    padding: 4px 0;
}
.header-navbar .navbar-brand{
    width: 212px;
}
.header-navbar .navbar-brand .sidebar-toggle-icon{
    display: flex;
    cursor: pointer;
    color: var(--secondary-color);
    font-size: 26px;
}
.header-navbar .small-logo img{
    max-height: 30px;
}
.header-navbar .add-general-comment{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--secondary-color) !important;
    padding-right: 0 !important;
}
.header-navbar .add-general-comment svg{
    font-size: 24px;
}
.header-navbar .add-general-comment span{
    color: var(--primary-color);
}
/* END: Header Navbar CSS */

/* START: Custom Sidebar CSS */
.custom-sidebar{
    background-color: var(--primary-color);
    border-color: var(--primary-color) !important;
    color: var(--white-color);
}
.custom-sidebar > div.ps-sidebar-container{
    background-color: var(--primary-color);
    padding: 20px 0;
}
.custom-sidebar .sidebar-close-icon{
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 2px;
}
.custom-sidebar .sidebar-close-icon:hover{
    color: var(--secondary-color);
}
.custom-sidebar .sidebar-user-info{
    padding: 10px 26px 20px 20px;
}
.custom-sidebar .sidebar-user-info .user-avatar{
    display: inline-block;
    width: 53px;
    height: 53px;
    border-radius: 100px;
    overflow: hidden;
    background-color: #fea466;
    border: 1px solid #fea466;
}
.custom-sidebar .sidebar-user-info h4{
    font-size: 16px;
    font-weight: 300;
    margin: 0;
}
.custom-sidebar .sidebar-user-info h5{
    font-size: 16px;
    font-weight: 500;
    margin: 3px 0 5px 0;
}
.custom-sidebar .ps-menu-root{
    font-size: 16px;
}
.custom-sidebar .ps-menu-root .ps-menuitem-root a.ps-menu-button{
    padding-left: 20px;
    padding-right: 20px;
}
.custom-sidebar .ps-menu-root .ps-menuitem-root a.ps-menu-button:hover{
    background-color: rgba(0,0,0,0.2);
}
.custom-sidebar .ps-menu-root .ps-menuitem-root a.ps-menu-button .ps-menu-icon .selected-avatar{
    max-width: 100%;
    border: 1px solid #33475c;
    border-radius: 100px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.custom-sidebar .separator-line{
    background-color: rgba(255,255,255,0.4);
    width: 100%;
    height: 1px;
    margin: 10px auto;
}
/* END: Custom Sidebar CSS */

/* START: Login Page CSS */
.login-page{
    background: #EEE;
    min-height: 100vh;
}
.login-page-header{
    background-color: var(--primary-color);
    border-bottom: 10px solid var(--secondary-color);
}
.login-page-header .brand-logo{
    max-width: 300px;
    margin: 0 auto;
}
.login-page-header .brand-logo img.logo{
    max-width: 100%;
}
.login-page-body{
    padding: 50px 0 0 0;
    max-width: 700px;
    margin: 0 auto;
}
/* END: Login Page CSS */
.profile-image-container{
    position: relative;
    padding-top: 40px;
}
.profile-image-container-inner{
    background-color: rgba(0,0,0,0.05);
    padding: 30px;
    border: 1px solid rgba(0,0,0,0.07);
    border-radius: var(--border-radius-small);
}
.profile-image-container .profile-image-box .profile-image-content .title{
    font-size: 20px;
    color: var(--primary-color);
    padding: 12px 0 6px 0;
}
.profile-image-container .profile-image-box .profile-image-content .user-avatar{
    display: inline-block;
    background-color: #fea466;
    border: 1px solid #fea466;
    width: 94px;
    height: 94px;
    border-radius: 100px;
    overflow: hidden;
}
.profile-image-container .profile-image-box .profile-image-content .user-avatar img{
    object-fit: contain;
    object-position: center;
    width: 94px;
    height: 94px;
}
.profile-image-container .year-picker{
    text-align: center;
    font-size: 20px;
    padding-left: 2.25rem;
}

.block-title{
    color: var(--primary-color);
    font-weight: 600;
}
.document-category-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    padding: 0.6rem 2rem;
}
.document-category-btn .btn-icon{
    color: var(--secondary-color)
}
.custom-loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.8);
}
.custom-loader.show{
    display: flex;
}
.custom-loader.hide{
    display: none;
}
.view-document-link{
    text-decoration: none;
    border-bottom: 1px solid #fea466;
    color: var(--secondary-color);
    padding: 0 0 4px 0;
}
.view-document-link:hover{
    border-bottom-color: transparent;
    color: var(--primary-color);
}
.view-document-block{
    margin-top: 2.2rem;
}
.no-data-found{
    min-height: 140px;
    border: 1px solid rgba(0,0,0,0.07);
    border-radius: var(--border-radius-small);
    padding: 20px;
    font-size: 20px;
    color: #FF0000;
    display: flex;
    align-items: center;
    justify-content: center;
}
table.custom-table thead tr th{
    background-color: rgba(65,91,116,0.33);
    color: #1c2833;
    text-transform: capitalize;
}
table.custom-table thead tr th,
table.custom-table tbody tr th,
table.custom-table tbody tr td{
    padding: 0.5rem 1rem;
}
table.custom-table tbody tr th,
table.custom-table tbody tr td{
    font-size: 14px;
}
table.custom-table thead tr th.id, table.custom-table tbody tr td.id,
table.custom-table thead tr th.action, table.custom-table tbody tr td.action{
    width: 1%;
    white-space: nowrap;
    text-align: center;
}
table.custom-table tbody tr td.action{
    font-size: 16px;
}
table.custom-table tbody tr td.action .delete-icon{
    color: #BB2D3B;
    cursor: pointer;
}
table.custom-table tbody tr td.action .delete-icon:hover{
    color: var(--secondary-color);
}

/* START: Modal CSS */
.modal .modal-dialog .modal-content .modal-header{
    padding: 0.725rem 1.5rem;
}
.modal .modal-dialog .modal-content .modal-header .modal-title{
    font-size: 1.25rem;
}
.modal .modal-dialog .modal-content .modal-header .close.btn{
    position: absolute;
    right: 5px;
    top: 5px;
}
/* END: Modal CSS */

/* Responsive CSS */
@media screen and (max-width: 991px) {
    /* START: Header Navbar CSS */
    .header-navbar .navbar-brand{
        width: auto;
    }
    .header-navbar .add-general-comment span{
        display: none;
    }
    /* END: Header Navbar CSS */
    .profile-image-container{
        padding: 15px;
    }
    .profile-image-container .profile-image-box .profile-image-content .title{
        font-size: 18px;
    }
    .profile-image-container .year-picker{
        font-size: 18px;
    }
}

